import React from "react"

import DefaultLayout from "../layouts/default"
import TwoThirdsGrid from "../components/layout/TwoThirdsGrid"
import Text from "../components/text/Text"
import HeadlineWithSub from "../components/text/HeadlineWithSub"
import ImageGrid from "../components/layout/ImageGrid"
import RoundImageWithSubtitle from "../components/RoundImageWithSubtitle"

const active = {
  title: "Garten"
}

const Garten = () =>
  <DefaultLayout image="/assets/images/IMG_0803.jpg" active={active}>
    <TwoThirdsGrid textRight>
      <div>
        <HeadlineWithSub>
          <h1>Unser Naturgarten</h1>
          <h4>
            wildharmonisch, sinnlich, vielfältig, vernetzt
          </h4>
        </HeadlineWithSub>
      </div>
      <Text>
        <p>
          <strong>
            Unser vielfältiger Naturgarten (ca. 1500qm) hat sich seit 2003 kontinuierlich weiterentwickelt. Er beherbergt zahlreichen Stauden (ein grosser Teil beschriftet), Beeren, Obst, Gemüse und einige Bäume. Ca. 70 verschiedene Rosen und um die 20 Staudenpfingstrosen. Sonnen- und Schattenbereiche, Sitzplätze mit Trockenmauern. Insgesamt lassen sich über 500 verschiedene Wildstauden oder Stauden, die den Wildarten nahe stehen oder bereits lange Zeit in Kultur sind, in unserem Garten zählen. Unser Garten ist etwas wild und ungezähmt, er ist organisch über die Jahre entstanden.
          </strong>
        </p>

        <p>
          Wir erhalten einige Zierpflanzen für Pro Specie Rara, bewirtschaften unseren Garten biologisch und sind Mitglied bei der Schweizerischen Gesellschaft für Gartenkultur. <br />
          Regelmässig laden wir interessiertes Publikum im Rahmen der Aktion offener-garten.ch in unseren Garten ein, um uns mit anderen Menschen auszutauschen.
        </p>
        <p>
          Die ausgeschriebenen Kurse finden im Garten oder in der abwechslungsreichen Umgebung von Mogelsberg statt.
        </p>

      </Text>
    </TwoThirdsGrid>
    <ImageGrid>
      <RoundImageWithSubtitle image="/assets/images/525586A6.jpg" text="Cicerbita alpina" />
      <RoundImageWithSubtitle image="/assets/images/FC27B7DF.jpg" text="Rosa moschata Umbrella" />
      <RoundImageWithSubtitle image="/assets/images/466645EB.jpg" text=" Paeonia mlokosewitschii" />
      <RoundImageWithSubtitle image="/assets/images/8C6B459A.jpg" text="Aster frikartii Wunder von Stäfa" />
      <RoundImageWithSubtitle image="/assets/images/399C2DD2.jpeg" text="Brunnera macrophylla" />
      <RoundImageWithSubtitle image="/assets/images/2BAAEB34.jpg" text="Anemone narcissiflora" />
    </ImageGrid>
  </DefaultLayout>

export default Garten