import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../styles/variables"

const ImageGridDiv = styled.section`
    width: 100%;
    margin-bottom: 50px;

    img {
        width: 135px;
        height: 128px;
    }

    max-width: 500px;

    @media screen AND (min-width: ${breakpoints.m}px) {
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 64px;
        grid-row-gap: 50px;
        margin-bottom: 60px;
        padding: 0 46px;
        text-align: center;

        img {
            width: 234px;
            height: 222px;
            display: block;
            margin: 0 auto;
        }
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        grid-column-gap: 84px;
        grid-row-gap: 60px;
        padding: 0 60px;
        margin-bottom: 60px;

        img {
            width: 293px;
            height: 278px;
        }
    }
`

const ImageGrid = ({children}) => <ImageGridDiv>{children}</ImageGridDiv>

export default ImageGrid