import React from "react"
import styled from "styled-components"
import { breakpoints, spacers } from "../styles/variables"

import RoundedImage from "./RoundedImage"

const ImgWithSubtitle = styled.div`
    > a, > div {
        display: grid;
        grid-template-columns: 135px 1fr;
        grid-gap: ${spacers.s}px;

        div {
                margin-bottom: ${spacers.s}px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

        h5 {
            //height: 100%;
            color: black;
            text-align: left;

            &.noHover {
                text-decoration: none;
            }
        } 

        @media screen AND (min-width: ${breakpoints.m}px) {
            display: block;

            img {
                margin-bottom: 22px;
            }

            h5 {
                height: auto;
                text-align: center;
            }
        }

        @media screen AND (min-width: ${breakpoints.l}px) {
            img {
                margin-bottom: 28px;
            }
        }
    }
`

const RoundImageWithSubtitle = ({image, text, link, subtext}) =>
<ImgWithSubtitle>
    {link !== undefined
    ? <a href={link}><RoundedImage image={image} /><div><h5>{text}</h5>{subtext ? <p className="subtext">{subtext}</p> :""}</div></a>
    : <div><RoundedImage image={image} /><div><h5 className="noHover inline-block">{text}{subtext ? <>&nbsp;<span className="light inline-block">{subtext}</span></> : ""}</h5></div></div>}
</ImgWithSubtitle>

export default RoundImageWithSubtitle;